import { json } from '@remix-run/node'
import type { LoaderFunctionArgs, MetaFunction } from '@remix-run/node'
import { useLoaderData } from '@remix-run/react'
import { ProductCard as BicycleCard } from '~/components/bicycle/cards'
import { ProductCard as PartCard } from '~/components/part/cards'
import { ProductCard as SupplyCard } from '~/components/supply/cards'
import { getAppAssetsBaseURL } from '~/utils/application.server'
import { createMeta } from '~/utils/application'
import {
  BicycleGenreModel,
  MakerDisplayIDModel,
  PrefectureCodeModel,
  ProductModelJSON,
} from '~/models'
import { ProductRepository } from '~/repositories/user/product.server'
import prefecture from '~/constants/prefecture'

type LoaderData = {
  assetsBaseURL: string
  products: ProductModelJSON[]
  keywords: string
}

export const meta: MetaFunction = args => createMeta({
  ...args,
  title: '検索ページ',
  isShortedTitle: false,
})

export const keys = {
  type: 'user-header-type-key',
  search: 'user-header-search-key',
  height: 'user-header-height-key',
  maker: 'user-hedaer-maker-key',
  prefecture: 'user-hedaer-prefecture-key',
  genre: 'user-hedaer-genre-key',
  minPrice: 'user-hedaer-min-price-key',
  maxPrice: 'user-hedaer-max-price-key',
}

export const loader = async ({ params, request }: LoaderFunctionArgs) => {
  const url = new URL(request.url)
  const type = url.searchParams.get(keys.type)
  const height = url.searchParams.get(keys.height)
  const prefectureCode = url.searchParams.get(keys.prefecture)
  const makerDisplayID = url.searchParams.get(keys.maker)
  const genre = url.searchParams.get(keys.genre)
  const minPrice = url.searchParams.get(keys.minPrice)
  const maxPrice = url.searchParams.get(keys.maxPrice)
  const repository = new ProductRepository()
  const keywords = params.keywords ? params.keywords.split(/\s+/).filter(keyword => keyword !== '') : []
  const productModels = await repository.findByKeywords({
    type: type ? Number(type) : undefined,
    keywords: keywords,
    makerDisplayID: makerDisplayID?.trim() ? new MakerDisplayIDModel(makerDisplayID) : undefined,
    prefectureCode: prefectureCode?.trim() ? new PrefectureCodeModel(Number(prefectureCode)) : undefined,
    height: height?.trim() ? Number(height) : undefined,
    genre: genre?.trim() ? new BicycleGenreModel(Number(genre)) : undefined,
    minPrice: minPrice?.trim() ? Number(minPrice) : undefined,
    maxPrice: maxPrice?.trim() ? Number(maxPrice) : undefined,
  })
  return json({
    keywords: params.keywords,
    assetsBaseURL: getAppAssetsBaseURL(),
    products: productModels,
  })
}

export default function SearchPage() {
  const data = useLoaderData<typeof loader>()

  if (!data) null

  return (
    <div className="py-4">
      <div className="py-4 flex justify-between align-bottom">
        <h2 className="text-lg">全ての商品<span className="ml-2">({data.products.length}件)</span></h2>
      </div>
      <div className="flex flex-row flex-wrap w-auto py-2">
        {data && data.products.map(product => (
          <div
            key={product.displayID}
            className="p-2 basis-1/2 sm:basis-1/3 md:basis-1/4 flex justify-center min-w-0"
          >
            {product.type === 0 ? (
              <BicycleCard
                key={product.displayID}
                product={product}
                assetsBaseURL={data.assetsBaseURL}
              />
            ) : product.type === 1 ? (
              <PartCard
                key={product.displayID}
                product={product}
                assetsBaseURL={data.assetsBaseURL}
              />
            ) : product.type === 2 ? (
              <SupplyCard
                key={product.displayID}
                product={product}
                assetsBaseURL={data.assetsBaseURL}
              />
            ) : ''}
          </div>
        ))}
      </div>
    </div>
  )
}
